import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import myprofile from "../../../assets/images/DefaultUserIcon.png";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { close } from "../../../features/eventSlice";
import { PostDescription, PostGroups } from "../postBlocks";
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { MESSAGE } from "../../../utils/ValidationMessage";
import { NOSPACE_REGEX } from "../../../utils/Constants";
import { BASE } from "../../../utils/Constants";
import { addMinutes, isAfter } from "date-fns";
import GoogleCalenderEvent from "../../../components/GoogleCalenderEvent";
import { GoogleOAuthProvider } from '@react-oauth/google';
import OutlookAuth from "../../modalBlocks/OutlookAuth";

const validationSchema = Yup.object().shape({
  quantity: Yup.string().required(MESSAGE.REQUIRED),
  comment: Yup.string().strict(true).max(100, "Comment should be less than 100").matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
});

const Pay = () => {
  const { data: post, pay, payment } = useSelector((state) => state?.event);
  const { adminInfo, profile } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => dispatch(close("pay"));

  
  let admin = adminInfo?.instituteid === post?.instituteid;
  let member = profile?.roledata.find((x) => x?.instituteid === post?.instituteid);
  let teacher = member?.role === "teacher";
  let viewPayList = admin || teacher;



  const [syncShow, setSyncShow] = useState(false);
  const [syncToken, setSyncToken] = useState(null);
  const [syncOn, setSyncOn] = useState(null);

  
  const updateParentState = newState => {
    setSyncShow(newState?.popup);
    setSyncToken(newState?.access_token);
    setSyncOn(newState?.sync_on);
  };




  const initialValues = {
    accept_payment : payment?.accept_payment,
    stripe_account_id : payment?.stripe_account_id,
    event_type: "pay",
    instituteid: post?.instituteid,
    postid: post?.postid,
    memberid: member?.memberid,
    group_name: post?.grps?.filter(Boolean).map((item) => item?.group_id),
    userid: profile?.id,
    comment: "",
    quantity: undefined,
    price: post?.paid_event_amount,
    description: post?.description,
    cardData: true,
    stripe_account_id: member?.stripe_account_id,
    sync:false
  };



  

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleClose();
      navigate("/payment", { state: { details: { ...values, post: post , price: values?.price * values?.quantity , access_token: syncToken , sync_on: syncOn } } });
    },
  });





  let content = null;
  if (viewPayList) {
      let totalprice = post?.postevent[0]
        ? parseFloat(post?.postevent.reduce((accumulator, currentObject) => {
            return accumulator + currentObject?.price;
          }, 0).toFixed(2))
        : 0.00;
      totalprice = totalprice.toFixed(2);

    const modalPayContent = post?.postevent[0]
      ? post?.postevent?.map((data, index) => {
          const user = post?.eventusers[0] && post?.eventusers?.find((e) => e?.id === data?.userid);
          console.log({data})
          return (
            <tr>
              <td>
                <div className="List-Attendees-user d-flex align-items-center">
                  <figure>
                    <img 
                        className="avatarimage" 
                        src={user?.profilepic ? BASE + "/" + user?.profilepic : myprofile} 
                        alt="icon" 
                        onError={(e) => {
                          e.target.src = myprofile; 
                        }}      
                    />
                  </figure>
                  <h4>{user ? user?.fullname : "NA"}</h4>
                </div>
              </td>
              <td>{data?.quantity}</td>
              <td>{data?.price ? `$${data.price.toFixed(2)}` : "$0.00"}</td>
              <td>{data?.confirmPaymentId}</td>
              <td>{data?.comment}</td>
            </tr>
          );
        })
      : null;

    content = (
      <div>
        <div className="all-posts-box similar-shadow-3">
          <h4>
            {post?.title}
            <Link to="#" className="calender-box">
              <i className="ti ti-calendar"></i>
            </Link>
          </h4>
          <PostGroups data={post} />
          <PostDescription data={post} />
        </div>
        <hr className="border-grey w-50 d-flex mx-auto" />
        <div className="row ">
          <div className="col-md-12 text-center">
            <div className="adult-info grey-bg d-flex justify-content-between align-items-center pt-3 pb-3">
              <h4 className="mb-0"> Total Transaction Amount</h4>
              <div className="adult-info-num d-flex justify-content-center">
                <span>
                  <b>${totalprice}</b>
                </span>
              </div>
            </div>
          </div>
        </div>
        <h4 className="sub-title mb-3 mt-4">List of Transactions</h4>
        <div className="over-table">
          {post?.postevent[0] ? (
            <table width="100%" className="list-attendees">
              <thead>
                <tr>
                  <th>Member Name</th>
                  <th>Quantity</th>
                  <th>Paid</th>
                  <th>Confirmation#</th>
                  <th>Comments</th>
                </tr>
              </thead>
              <tbody>{modalPayContent}</tbody>
            </table>
          ) : (
            <div className="text-center">No data found</div>
          )}
        </div>
      </div>
    );
  } else {
    const dueDate = new Date(post?.due_date);
    const startDate = new Date(post?.start_date);
    const currentDate = new Date();
    const thirtyMinutesAfterStart = addMinutes(startDate, 30);
    dueDate?.setDate(dueDate?.getDate() + 1);
    const canPay = !isAfter(currentDate, dueDate) && !isAfter(currentDate, thirtyMinutesAfterStart);

    if (canPay) {
      content = (
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
            <div className="pay-box-content mb-4">
              <h4>Description</h4>
              <PostDescription data={post} />
            </div>
            <hr className="grey-line" />
            <div className="mb-4">
              <Field name="quantity" as="select" className="form-control text-dark">
                <option value="">Quantity</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
              </Field>
              <ErrorMessage component="p" className="text-danger" name="quantity" />
            </div>
            <div className="mb-4 textarea-box">
              <Field as="textarea" className="form-control" name="comment" placeholder="Add a comment" />
              <ErrorMessage component="p" className="text-danger" name="comment" />
            </div>


            <label className="coustom-checkbox s-check-box me-4">
                  <Field type="checkbox" name="sync"  onChange={(e) => {if(e.target.checked){
                      formik.setFieldValue('sync',true);
                      setSyncShow(true);
                  } else{   formik.setFieldValue('sync',false); } }}  />
                  <span className="checkmark"></span>
                  <p>Add to external calendar</p>
            </label>


            {formik.values.quantity && 
            
            <div className="pay-box-content mb-4 d-flex justify-content-between">
              <h4>Total Amount</h4>
              <p>
                <b className="blue-text">${(post?.paid_event_amount * (formik?.values?.quantity ?? 1)).toFixed(2)} </b>
              </p>
            </div>
            
            }


            <div className="btn-group-modal mt-4">
              <button  className="btn full-btn hvr-sweep-to-right">Pay</button>
            </div>
          </Form>
        </FormikProvider>
      );
    } else {
      content = <p>Payment is no longer available.</p>;
      setTimeout(() => handleClose(), 3000);
    }
  }
  return (

    <>
    <Modal size={viewPayList ? "xl" : "md"} centered show={pay} onHide={handleClose} keyboard={false}>
      <Modal.Header className="p-4 border-0" closeButton>
        <Modal.Title className="ms-auto event-title">Pay</Modal.Title>
      </Modal.Header>
      <div className="modal-body px-5">{content}</div>
    </Modal>
      <Modal show={syncShow} onHide={()=>{setSyncShow(false);   formik.setFieldValue('sync',false);}}>
      <Modal.Header closeButton>
        <Modal.Title>Add to calendar</Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <div className='syncButton' style={{textAlign:"center"}}>

      <button   onClick={() => {
                                setSyncShow(false);
                        
                                setSyncToken("");
                                setSyncOn('Apple');
                            }}><i class="fa fa-calendar" aria-hidden="true"></i> &nbsp; Calendar apps (ICS) </button>

        <br></br>
        <br></br>

      <GoogleOAuthProvider clientId="806967399943-pr80m53f83bofv3ghaoijo59bvnjuopb.apps.googleusercontent.com">
      <GoogleCalenderEvent updateParentState={updateParentState}></GoogleCalenderEvent>
      </GoogleOAuthProvider>
      </div>
      <br></br>
      <OutlookAuth updateParentState={updateParentState}/>

      </Modal.Body>

    </Modal>
    </>

    
  );
};

export default Pay;
