import React, { useState, useEffect } from "react";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { BASE, EMAIL_REGEX, MOBILE_REGEX, NOSPACE, SUPPORTED_FORMATS } from "../../../utils/Constants";
import Swal from "sweetalert2";
import SchoolAdmin from "../../../services/admin.service";
import { useNavigate } from "react-router";
import profileImg from "../../../assets/images/DefaultUserIcon.png";
import { MESSAGE } from "../../../utils/ValidationMessage";
import { States } from "../../../utils/Options";
import { useDispatch, useSelector } from "react-redux";
import { setUserImage } from "../../../features/authSlice";
import { Error, Note, Success } from "../../../utils/Alert";
import { handleBlurTrim } from "../../../utils/FileSize";

const EditProfile = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const dispatch = useDispatch()
  const [data, setData] = useState({});
  const [profileImage, setProfileImage] = useState("");

  useEffect(() => {
    SchoolAdmin.viewInstitute({allinstituteids : auth?.allInstitutesIds})
      .then((response) => {
        setData(response?.data);
        const { profilepic } = response?.data;
        let path = profilepic ? `${BASE}/${profilepic}` : "";
        setProfileImage(path);
      })
      .catch((err) => {
        const { message } = err?.response?.data;
        Swal.fire({ text: message ? message : Note.Err, ...Error });
      });
  }, []);

  const initialValues = {
    firstname: data?.firstname ? data?.firstname : "",
    lastname: data?.lastname ? data?.lastname : "",
    acronym: data?.acronym ? data?.acronym : "",
    institutename: data?.institutename ? data?.institutename : "",
    phoneno: data?.phoneno ? data?.phoneno : "",
    streetAddress: data?.streetAddress ? data?.streetAddress : "",
    state: data?.state ? data?.state : "",
    city: data?.city ? data?.city : "",
    zipcode: data?.zipcode ? data?.zipcode.toString() : "",
    profilepic: data?.profilepic ? data?.profilepic : "",
    email: data?.email ? data?.email : "",
  };

  const validationSchema = Yup.object().shape({
    profilepic: Yup.mixed()
      .test("fileFormat", "Unsupported Format", (value) => {
        if (typeof value === "string") {
          return true;
        } else {
          return !value || (value && SUPPORTED_FORMATS.includes(value?.type));
        }
      })
      .test("fileSize", "File too large (max size 5MB)", (value) => {
        if (typeof value === "string" || typeof value === "undefined") {
          return true;
        } else {
          return value && value?.size <= 5000000;
        }
      }),
    firstname: Yup.string().max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED),
    lastname: Yup.string().max(30, "Last name should be less than 30 chars").required(MESSAGE.REQUIRED),
    institutename: Yup.string().required(MESSAGE.REQUIRED),
    acronym: Yup.string().required(MESSAGE.REQUIRED),
    phoneno: Yup.string().required(MESSAGE.REQUIRED).matches(MOBILE_REGEX, MESSAGE.PHONE),
    email: Yup.string().strict(true).matches(EMAIL_REGEX, MESSAGE.EMAIL).max(255).required(MESSAGE.REQUIRED),
    streetAddress: Yup.string().required(MESSAGE.REQUIRED),
    city: Yup.string().required(MESSAGE.REQUIRED),
    state: Yup.string().required(MESSAGE.REQUIRED),
    zipcode: Yup.string().required(MESSAGE.REQUIRED).matches(/^[0-9]{5}$/, "Invalid zip code"),
  });

  const validationSchemaMember = Yup.object().shape({
    profilepic: Yup.mixed()
      .test("fileFormat", "Unsupported Format", (value) => {
        if (typeof value === "string") {
          return true;
        } else {
          return !value || (value && SUPPORTED_FORMATS.includes(value?.type));
        }
      })
      .test("fileSize", "File too large (max size 5MB)", (value) => {
        if (typeof value === "string" || typeof value === "undefined") {
          return true;
        } else {
          return value && value?.size <= 5000000;
        }
      }),
    firstname: Yup.string().max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED),
    lastname: Yup.string().max(30, "Last name should be less than 30 chars").required(MESSAGE.REQUIRED),
    email: Yup.string().strict(true).matches(EMAIL_REGEX, MESSAGE.EMAIL).max(255).required(MESSAGE.REQUIRED),
    phoneno: Yup.string().required(MESSAGE.REQUIRED).matches(MOBILE_REGEX, MESSAGE.PHONE),
  });

  const onSubmit = (values, { resetForm, setSubmitting }) => {
  Object.keys(values)?.forEach((k) => (typeof values[k] === "string" ? (values[k] = values[k]?.trim().replace(/  +/g, " ")) : values[k]));
  
  let formData = new FormData();
  
  Object.keys(values)?.map((item) => {
    if (item === "profilepic") {
      if (values[item] instanceof File) {
        formData.append(item, values[item]);
      } else {
        formData.append(item, data?.profilepic || "");
      }
    } else {
      formData.append(item, values[item]);
    }
  });
  
  formData.append("logintype", data?.logintype);
  formData.append("userid", data?.userid);
  formData.append("institute_id", data?.institute_id);

  SchoolAdmin.updateInstituteProfile(formData)
    .then((res) => {
      const { profilepic } = res?.data?.data[0];
      if (profilepic) {
        dispatch(setUserImage(profilepic));
      }

      Swal.fire({ text: "Updated successfully", ...Success});
      navigate("/profile");
    })
    .catch((err) => {
      console.log("err",err?.response?.data);
      const { message } = err?.response?.data;
      Swal.fire({ text: message ? message : Note.Err, ...Error });
      resetForm();
      setSubmitting(false);
    });
};

useEffect(() => {
  if (!auth) {
    navigate("/home");
  }
}, [auth, navigate]);

if (!auth) {
  return
}

  const handleKeyPress = (event) => {
    const charCode = event?.which ? event?.which : event?.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  return (
    <div className="wraper-inner bg-grey space-top">
      <section className="edit-profile pt-4 pb-5 min-box">
        <div className="container">
          <div className="row">
            <div className="col-md-6 m-auto">
              <div className="similar-shadow-box pb-5">
                <h3 className="inner-title mb-4 ">Edit Profile</h3>
                <Formik enableReinitialize initialValues={initialValues} validationSchema={auth?.logintype.includes("admin") ? validationSchema : validationSchemaMember} onSubmit={onSubmit}>
                  {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, dirty }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="upload-photo">
                        <div className="avatar-upload">
                          <div className="avatar-edit">
                            <input
                              type="file"
                              id="imageUpload"
                              accept=".png, .jpg, .jpeg"
                              name="profilepic"
                              onChange={(e) => {
                                const file = e?.target?.files[0];
                                if (file && file.type.startsWith('image/')) {
                                  setFieldValue("profilepic", file);
                                  setProfileImage(URL.createObjectURL(file));
                                } else {
                                  Swal.fire({text: "Please upload an image",icon: 'error', ...Error});
                                }
                              }}
                              
                            />
                            <label htmlFor="imageUpload" className="img-upload-icon">
                              <i className="ti ti-pencil"></i>
                            </label>
                          </div>
                          <div className="text-center profile-head">
                            <figure>
                              <img 
                                  src={profileImage ? profileImage : profileImg}
                                  alt="logo" 
                                  onError={(e) => {
                                    e.target.src = profileImg; 
                                  }}  
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div className="mb-4 text-center">
                        <span className="text-danger">
                          <ErrorMessage name="profilepic" />
                        </span>
                      </div>
                      <div className="mb-4">
                        <label className="custom-field one">
                          <input type="text" placeholder=" " name="firstname" value={values?.firstname} onChange={handleChange}  onBlur={handleBlurTrim(handleChange)} />
                          <span className="placeholder">First Name</span>
                          <span className="text-danger">
                            <ErrorMessage name="firstname" />
                          </span>
                          <i className="ti ti-user"></i>
                        </label>
                      </div>
                      <div className="mb-4">
                        <label className="custom-field one">
                          <input type="text" placeholder=" " name="lastname" value={values?.lastname} onChange={handleChange}  onBlur={handleBlurTrim(handleChange)} />
                          <span className="placeholder">Last Name</span>
                          <i className="ti ti-user"></i>
                          <span className="text-danger">
                            <ErrorMessage name="lastname" />
                          </span>
                        </label>
                      </div>
                      {auth.logintype.includes("admin") && (
                        <>
                          <div className="mb-4">
                            <label className="custom-field one">
                              <input type="text" placeholder=" " name="institutename" value={values?.institutename} onChange={handleChange}  onBlur={handleBlurTrim(handleChange)} />
                              <span className="placeholder">Institute Name</span>
                              <i className="ti ti-building"></i>
                              <span className="text-danger">
                                <ErrorMessage name="institutename" />
                              </span>
                            </label>
                          </div>
                          <div className="mb-4">
                            <label className="custom-field one">
                              <input type="text" placeholder=" " name="acronym" value={values?.acronym} onChange={handleChange}  onBlur={handleBlurTrim(handleChange)} />
                              <span className="placeholder">Institute Acronym</span>
                              <i className="ti ti-building"></i>
                              <span className="text-danger">
                                <ErrorMessage name="acronym" />
                              </span>
                            </label>
                          </div>

                          <div className="mb-4">
                            <label className="custom-field one">
                              <input type="text" placeholder=" " name="streetAddress" value={values?.streetAddress} onChange={handleChange} onBlur={handleBlurTrim(handleChange)} />
                              <span className="placeholder">Street Address</span>
                              <i className="ti ti-map-pin"></i>
                              <span className="text-danger">
                                <ErrorMessage name="streetAddress" />
                              </span>
                            </label>
                          </div>
                          <div className="mb-4">
                            <label className="custom-field one">
                              <input type="text" placeholder=" " name="city" value={values?.city} onChange={handleChange} onBlur={handleBlurTrim(handleChange)} />
                              <span className="placeholder">City</span>
                              <i className="ti ti-map-pin"></i>
                              <span className="text-danger">
                                <ErrorMessage name="city" />
                              </span>
                            </label>
                          </div>
                          <div className="mb-4">
                            <label className="custom-field one custom-select">
                              <i className="ti ti-map-pin"></i>
                              <select name="state" value={values?.state} onChange={handleChange} onBlur={handleBlur} className="form-control selected-text">
                                {States?.map((item, index) => (
                                  <option key={index} value={item?.value}>
                                    {item?.label}
                                  </option>
                                ))}
                              </select>
                              <span className="text-danger">
                                <ErrorMessage name="state" />
                              </span>
                            </label>
                          </div>

                          <div className="mb-4">
                            <label className="custom-field one">
                              <input type="text" placeholder=" " name="zipcode" value={values?.zipcode} onKeyPress={handleKeyPress}  onChange={handleChange} onBlur={handleBlurTrim(handleChange)} />
                              <span className="placeholder">Zipcode</span>
                              <i className="ti ti-map-pin"></i>
                              <span className="text-danger">
                                <ErrorMessage name="zipcode" />
                              </span>
                            </label>
                          </div>
                        </>
                      )}

                      <div className="mb-4">
                        <label className="custom-field one">
                          <input type="text" placeholder=" " name="phoneno"  onKeyPress={handleKeyPress}  onChange={handleChange} onBlur={handleBlurTrim(handleChange)} value={values?.phoneno} />
                          <span className="placeholder">Mobile Number</span>
                          <i className="ti ti-phone"></i>
                          <span className="text-danger">
                            <ErrorMessage name="phoneno" />
                          </span>
                        </label>
                      </div>

                      <div className="mb-4">
                        <label className="custom-field one">
                          <input type="text" placeholder=" " name="email" onChange={handleChange} onBlur={handleBlurTrim(handleChange)} value={values?.email} />
                          <span className="placeholder">Email</span>
                          <i className="ti ti-mail"></i>
                          <span className="text-danger">
                            <ErrorMessage name="email" />
                          </span>
                        </label>
                      </div>

                      <div className="btn-box m-5 d-flex">
                        <button className="btn min-btn black-btn hvr-sweep-to-right me-2" onClick={() => navigate("/profile")}>
                          Cancel
                        </button>
                        <button disabled={!dirty || isSubmitting} type="submit" className="btn  min-btn hvr-sweep-to-right ms-2">
                          Update
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditProfile;