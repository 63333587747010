import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SchoolAdmin from "../services/admin.service";
import Post from "../services/post.service";

export const getAllPosts = createAsyncThunk("posts/getAllPosts", async (values) => {

  const { data } = await Post.viewAllPost(values);
  return data;
});

export const likeDislikePost = createAsyncThunk("posts/likeDislikePost", async (values) => {
  const { data } = await Post.likeDislikePost(values);
  return data;
});

export const addCommentToPost = createAsyncThunk("posts/addCommentToPost", async (values) => {
  const { data } = await Post.addComment(values);
  return data;
});

export const addReminderToPost = createAsyncThunk("posts/addReminderToPost", async (values) => {
  const { data } = await SchoolAdmin.createNotifications(values);
  return data;  
});

export const editCommentInPost = createAsyncThunk("posts/editCommentInPost", async (values) => {
  const { data } = await Post.editComment(values);
  return data;
});

export const deleteCommentInPost = createAsyncThunk("posts/deleteCommentInPost", async (values) => {
  const { data } = await Post.deleteComment(values);
  return data;
});

export const getAllGroupPosts = createAsyncThunk("posts/getAllGroupPosts", async (values) => {
  const { data } = await Post.viewAllPostOfGroup(values);
  return data;
});

export const getAllDMPosts = createAsyncThunk("posts/getAllDMPosts", async (values) => {
  const { data } = await Post.viewAllDMPost(values);
  return data;
});

export const deletePosts = createAsyncThunk("posts/deletePosts", async (values) => {
  const { data } = await Post.deletePost(values);
  return data.data;
});

export const rsvpPostEvent = createAsyncThunk("posts/rsvpPostEvent", async (values, thunkAPI) => {
  try {
    const { data } = await Post.addPostEvent(values);
    return data;
  } catch (error) {
    // You can access the error response here
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const editRsvpEvent = createAsyncThunk("posts/editRsvpEvent", async (values, thunkAPI) => {
  try {
    const { data } = await Post.editPostEvent(values);
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deleteRsvpEvent = createAsyncThunk("posts/deleteRsvpEvent", async (values, thunkAPI) => {
  try {
    const { data } = await Post.deletePostEvent(values);
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getAllParticipatedGroups = createAsyncThunk("posts/getAllParticipatedGroups", async (values) => {
  const { data } = await SchoolAdmin.getAllParticipatedGroups(values);
  return data;
});

export const getAllMemberOfSelectedGroup = createAsyncThunk("posts/getAllMemberOfSelectedGroup", async (values) => {
  const { data } = await SchoolAdmin.getAllMemberOfSelectedGroup(values);
  return data;
});

const toggle = (input) => !input;

const initialState = {
  refresh: true,
  loading: false,
  posts: [],
  list: [],
  selectedPost: null,
  pageNumber: 1,
  totalPages: 1,
  searched: "",
  error: null,
};

export const postSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    currentPage: (state, action) => {
      state.pageNumber = action?.payload;
    },
    setSearched: (state, action) => {
      state.searched = action?.payload;
      state.pageNumber = 1;
    },
    selectedPost: (state, { payload }) => {
      state.selectedPost = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPosts?.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPosts?.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.posts = payload?.data;
        state.totalPages = payload?.totalPages;
      })
      .addCase(getAllPosts?.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllParticipatedGroups?.fulfilled, (state, { payload }) => {
        state.list = payload?.data?.grps;
        // if (state.totalPages < payload.data.totalPages) {
        //   state.totalPages = payload.data.totalPages;
        // }
      })
      .addCase(likeDislikePost.fulfilled, (state, action) => {

        const { data } = action?.payload;
        const { postid } = action?.meta.arg;
        const existingPost = state?.posts?.find((post) => post?.id === postid);


        if (existingPost) {
          if (data) {
            existingPost.like_count++;
            existingPost.likeflag = true;
          } else {
            existingPost.like_count--;
            existingPost.likeflag = false;
          }
        }
      })
      .addCase(addCommentToPost?.fulfilled, (state, action) => {
        const { postid } = action?.meta.arg;
        const { data } = action?.payload;
        const existingPost = state?.posts.find((post) => post?.id === postid);
        if (existingPost) {
          let commentList = existingPost?.total_comment[0] ? existingPost?.total_comment : [];
          commentList?.push(data);
          existingPost.total_comment = commentList;
          existingPost.comment_count++;
        }
      })
      .addCase(addReminderToPost?.fulfilled, (state, action) => {
        const { postid } = action?.meta?.arg;
        const { data } = action?.payload;

        const existingPost = state?.posts?.find((post) => post?.id === postid);
        if (existingPost) {
          existingPost.remind_me = data?.time;
        }
      })
      .addCase(deleteCommentInPost?.fulfilled, (state, action) => {
        const { postid } = action?.meta.arg;
        const { data } = action?.payload;
        const existingPost = state?.posts?.find((post) => post?.postid === postid);
        if (existingPost) {
          existingPost.total_comment = data;
          existingPost.comment_count--;
        }
      })
      .addCase(editCommentInPost?.fulfilled, (state, action) => {
        const { postid, commentid } = action?.meta?.arg;
        const { data } = action?.payload;
        const existingPost = state?.posts?.find((post) => post?.id === postid);
        if (existingPost) {
          let commentList = existingPost?.total_comment;
          let index = commentList?.findIndex((item) => item?.id === commentid);
          commentList[index] = data;
          existingPost.total_comment = commentList;
        }
      })
      .addCase(getAllGroupPosts?.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllGroupPosts?.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.posts = payload?.data;
        state.totalPages = payload?.totalPages;
      })
      .addCase(getAllGroupPosts.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllMemberOfSelectedGroup?.fulfilled, (state, { payload }) => {
        state.list = payload?.users;
        // if (state.totalPages < payload.totalPages) {
        //   state.totalPages = payload.totalPages;
        // }
      })
      .addCase(getAllDMPosts?.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getAllDMPosts?.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.posts = payload?.data;
        state.totalPages = payload?.totalPages;
      })
      .addCase(deletePosts?.fulfilled, (state, { payload }) => {
        state.loading = true;
        state.refresh = toggle(state?.refresh);
      })
      .addCase(rsvpPostEvent?.fulfilled, (state, action) => {
        const { postid } = action?.meta?.arg;
        const existingPost = state?.posts?.find((post) => post?.postid === postid);
        let rsvpDataList = existingPost?.postevent[0] ? [...existingPost?.postevent] : [];
        rsvpDataList?.push(action?.payload);
        let rsvpUserList = existingPost?.eventusers[0] ? [...existingPost?.eventusers] : [];
        rsvpUserList?.push({ id: action?.payload?.userid });
        existingPost.postevent = rsvpDataList;
        existingPost.eventusers = rsvpUserList;
      })
      .addCase(rsvpPostEvent?.rejected, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(editRsvpEvent?.fulfilled, (state, action) => {
        const { postid } = action?.meta?.arg;
        const existingPost = state?.posts?.find((post) => post?.postid === postid);
        let rsvpDataList = existingPost?.postevent[0] ? [...existingPost?.postevent] : [];
        let newDataList = rsvpDataList?.map((data) => (data?.postid === postid ? action?.payload : data));
        existingPost.postevent = newDataList;
      })
      .addCase(editRsvpEvent?.rejected, (state, { payload }) => {
        console.log(payload);
        state.error = payload;
      })
      .addCase(deleteRsvpEvent?.fulfilled, (state, action) => {
        const { postEventID, postid, userid } = action?.meta?.arg;
        const existingPost = state?.posts?.find((post) => post?.postid === postid);
        let rsvpDataList = existingPost?.postevent;
        let newDataList = rsvpDataList?.filter((data) => data?.id !== postEventID);
        existingPost.postevent = newDataList;
        let rsvpUserList = existingPost?.eventusers?.filter((data) => data?.id !== userid);
        existingPost.eventusers = rsvpUserList;
      })
      .addCase(deleteRsvpEvent?.rejected, (state, { payload }) => {
        state.error = payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const { currentPage, setSearched, selectedPost } = postSlice?.actions;

export default postSlice?.reducer;
