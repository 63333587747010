import { useEffect, useState } from "react";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import { States } from "../../utils/Options";
import { MESSAGE } from "../../utils/ValidationMessage";
import { CARD_REGEX, NOSPACE_REGEX } from "../../utils/Constants";
import Finance from "../../services/finance.service";
import DatePicker from "react-datepicker";
import { Error, Note, Success } from "../../utils/Alert";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { downloadICS } from "../../utils/Ics";
import { handleBlurTrim } from "../../utils/FileSize";

const getCardType = (cardNumber) => {
  if (cardNumber && cardNumber?.match(/^(34|37)/)) {
    return 'amex';
  } else {
    return 'other';
  }
};

const validationSchema = Yup.object().shape({
  fname: Yup.string().strict(true).trim().max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED),
  lname: Yup.string().strict(true).trim().max(30, "Last name should be less than 30 chars").required(MESSAGE.REQUIRED),
  card_no: Yup.string().matches(CARD_REGEX, "Invalid card number").required(MESSAGE.REQUIRED),
  cvv: Yup.string().when('card_no', {
    is: (cardNumber) => getCardType(cardNumber) === 'amex',
    then: Yup.string().matches(/^[0-9]{4}$/, 'Invalid cvv').required(MESSAGE.REQUIRED),
    otherwise: Yup.string().matches(/^[0-9]{3}$/, 'Invalid cvv').required(MESSAGE.REQUIRED),
  }),
  address_line1: Yup.string().strict(true).trim().max(255, "Street address should be less than 255 chars").required(MESSAGE.REQUIRED),
  address_city: Yup.string().strict(true).trim().max(255, "City should be less than 255 chars").required(MESSAGE.REQUIRED),
  address_state: Yup.string().strict(true).trim().max(255, "State should be less than 255 chars").required(MESSAGE.REQUIRED),
  address_zip: Yup.string().strict(true).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE).matches(/^[0-9]{5}$/, "Invalid zip code").required(MESSAGE.REQUIRED),
  exp_date: Yup.date().nullable().required(MESSAGE.REQUIRED),
  accept: Yup.bool().oneOf([true], "Please accept Terms & Conditions and Privacy Policy"),
});

const Payment = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  // const { details } = state;
  const [details, setDetails] = useState(state?.details)
  const { adminInfo } = useSelector((state) => state?.auth);
  const [data, setData] = useState({});
  const initialValues = {
    title: details?.post?.title,
    fname: "",
    lname: "",
    address_line1: "",
    address_city: "",
    address_state: "",
    address_zip: "",
    card_no: "",
    cvv: "",
    exp_date: null,
    accept: false,
  };

  useEffect(() => {
    Finance.viewSettings().then((res) => setData(res?.data?.data));
  }, []);

  const onSubmit = async (values, { resetForm }) => {
    const obj = { ...values, ...details, amount: data?.ad_post_fee, exp_date: values?.exp_date?.toISOString(), instituteid: adminInfo?.instituteid };

    let currentDate = new Date();
    let emailDate = new Date().toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    })
    try {
      let res;
      if (details?.event_type !== "sponsered") {

        res = await Finance.payment({ ...values, ...details, currentDate, emailDate, exp_date: values?.exp_date?.toISOString() });
      } else {
        res = await Finance.addCampaign(obj);
      }

      if (details?.sync_on == 'Apple') {
        downloadICS(details?.post);
      }

      Swal.fire({ text: res?.data?.message, ...Success });
      navigate(-1);
      resetForm();
    } catch (error) {
      const { message } = error?.response?.data;
      Swal.fire({ text: message ? message : Note?.Err, ...Error });
    }
  };


  const handleNumberInput = (event) => {
    const value = event.target?.value;
    event.target.value = value?.replace(/\D/g, '');
  };

  const handleKeyPress = (event) => {
    const charCode = event?.which ? event?.which : event?.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  const today = new Date();
  const minDate = new Date(today?.getFullYear(), today?.getMonth());

  useEffect(() => {
    if (!state) {
      navigate("/home");
    }
  }, [state, navigate]);

  if (!state) {
    return
  }

  return (
    <>
      <div className="wraper-inner bg-grey space-top">
        <section className="create-new-post pt-4 pb-5 min-box">
          <div className="container">
            <div className="similar-shadow-box pb-5">
              <h3 className="inner-title mb-4">Payment</h3>
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ values, isSubmitting, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="msge-sucess text-center mb-4 full-box">
                      <p>
                        Total Amount Due: <b className="blue-link ms-2"> ${details?.price ? details?.price?.toFixed(2) : data?.ad_post_fee?.toFixed(2)}</b>
                      </p>
                    </div>
                    <div className="row">
                      <div className="mb-4 col-md-6">
                        <Field type="text" name="fname" placeholder="First Name" className="form-control" onBlur={handleBlurTrim(handleChange)} />
                        <span className="custom-field text-danger">
                          <ErrorMessage name="fname" />
                        </span>
                      </div>
                      <div className="mb-4 col-md-6">
                        <Field type="text" name="lname" placeholder="Last Name" className="form-control" onBlur={handleBlurTrim(handleChange)} />
                        <span className="custom-field text-danger">
                          <ErrorMessage name="lname" />
                        </span>
                      </div>
                      <div className="mb-4 col-md-12">
                        <Field type="text" name="address_line1" placeholder="Billing Address" className="form-control" onBlur={handleBlurTrim(handleChange)} />
                        <span className="custom-field text-danger">
                          <ErrorMessage name="address_line1" />
                        </span>
                      </div>
                      <div className="mb-4 col-md-4">
                        <Field type="text" name="address_city" placeholder="City" className="form-control" onBlur={handleBlurTrim(handleChange)} />
                        <span className="custom-field text-danger">
                          <ErrorMessage name="address_city" />
                        </span>
                      </div>
                      <div className="mb-4 col-md-4">
                        <select className="form-control" name="address_state" type="text" onChange={handleChange} onBlur={handleBlur}>
                          {States?.map((item, index) => (
                            <option key={index} value={item?.value}>
                              {item?.label}
                            </option>
                          ))}
                        </select>
                        <span className="custom-field text-danger">
                          <ErrorMessage name="address_state" />
                        </span>
                      </div>
                      <div className="mb-4 col-md-4">
                        <Field
                          type="text"
                          name="address_zip"
                          placeholder="Zip Code"
                          className="form-control"
                          onKeyPress={handleKeyPress}
                          onInput={handleNumberInput}
                        />
                        <span className="custom-field text-danger">
                          <ErrorMessage name="address_zip" />
                        </span>
                      </div>
                      <div className="mb-4 col-md-12">
                        <Field type="text" name="card_no" placeholder="Card Number" className="form-control" onKeyPress={handleKeyPress}
                          onInput={handleNumberInput} />
                        <span className="custom-field text-danger">
                          <ErrorMessage name="card_no" />
                        </span>
                      </div>
                      <div className="mb-4 col-md-6">
                        <Field
                          type="text"
                          name="cvv"
                          placeholder="CVV"
                          className="form-control"
                          onKeyPress={handleKeyPress}
                          onInput={handleNumberInput}
                        />
                        <span className="custom-field text-danger">
                          <ErrorMessage name="cvv" />
                        </span>
                      </div>
                      <div className="mb-4 col-md-6">
                        <DatePicker
                          placeholderText="Expiry Date MM/YYYY"
                          selected={values?.exp_date}
                          dateFormat={"MM/yyyy"}
                          className="form-control"
                          name="exp_date"
                          onChange={(date) => setFieldValue(`exp_date`, date)}
                          minDate={minDate}
                          onChangeRaw={(e) => e.preventDefault()}
                          showMonthYearPicker
                          autoComplete="off"
                        />

                        <span className="text-danger">
                          <ErrorMessage name="exp_date" />
                        </span>
                      </div>
                      <div className="mb-1 terms-condition-box d-flex">
                        <label className="coustom-checkbox ">
                          <Field type="checkbox" name="accept" checked={values?.accept} />
                          <span className="checkmark"></span>
                          <p className="title-lable">
                            Accept
                            <Link to="/termsandcondition" target="_blank" className="blue-link ms-1 me-1">
                              Terms & Conditions
                            </Link>
                            and
                          </p>
                        </label>

                        <p className="title-lable-sec">
                          <Link to="/privacypolicy" target="_blank" className="blue-link ms-1">
                            Privacy Policy
                          </Link>
                        </p>
                      </div>
                      <div className="mb-3">
                        <span className="text-danger">
                          <ErrorMessage name="accept" />
                        </span>
                      </div>

                      <div className="mt-4 btn-box">
                        <button type="submit" className="btn big-btn hvr-sweep-to-right" disabled={isSubmitting}>
                          Pay Now
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
              <div className="text-center mt-3">
                <p>
                  Please note all payments are final. Please reach out to your{details?.event_type === "sponsered" ? " Administrator" : " Institution"} for refund or cancellation.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Payment;
