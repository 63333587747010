import React, { useState, useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Post from "../../../services/post.service";
import Swal from "sweetalert2";
import logo from "../../../assets/images/logo-form.png"
import DateFormat from "../../../utils/DateFormat";
import SyncCalender from "../../../components/modalBlocks/SyncCalender";
import { getAllDMPosts, getAllPosts } from "../../../features/postSlice";
import { useDispatch, useSelector } from "react-redux";


const locales = {
  "en-US": require("date-fns"),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const myEventsList = [];

const MyCalendar = () => {
  const navigate = useNavigate();
  const [myEvents, setEvents] = useState(myEventsList);
  const { allInstitutesIds, profile } = useSelector((state) => state?.auth);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const adminInstitute = profile?.roledata?.filter((item) => item?.role === "admin").map((item) => item?.instituteid);
  const dispatch = useDispatch();

  useEffect(() => {
    Post.viewCalendarPost({institutes:allInstitutesIds, adminInstitute}).then((res) => {
    
      let arr = res?.data?.data?.map((x) => ({
        id:x?.id, 
        title:x?.title, 
        realdm: x?.realdm, 
        dmpost:x?.dmpost, 
        description:x?.description,
        location:x?.location,
        start: new Date(x?.start_date), 
        end: new Date(x?.end_date) ,  
        canceled: x?.is_cancelled}));
      setEvents(arr);
    });
  }, []);

  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      const title = window.prompt("New Event name");
      if (title) {
        setEvents((prev) => [...prev, { start, end, title }]);
      }
    },
    [setEvents]
  );


  const handleSelectEvent = useCallback((event) => {

    
    let eventContent = `<div class="event-popup-box">

    <h1>${event?.title}</h1>
    <p>${event?.description}</p>
    <div class="popup-info">
     <ul> 
      <li><i class="ti ti-calendar"></i><span>Start Date : ${DateFormat(event?.start, true)}</span></li>
      <li><i class="ti ti-calendar"></i><span>End Date : ${DateFormat(event?.end, true)}</span></li>
      <li><i class="ti ti-map-pin"></i><span>${event?.location}</span></li>
     </ul> 
    </div>

    </div>`;
    
    Swal.fire({
      title: 'Event Detail',
      html: eventContent,
      confirmButtonText: 'Go to Post',
    }).then(async(result) => {
      if (result?.isConfirmed) {   
        if (event?.dmpost && event?.realdm) {
          navigate('/sendmessage', { state: { priorityIds: [event?.id] } });
        } else {
          navigate('/home', { state: { priorityIds: [event?.id] } });
        }   
      }
    });
  
  
  }, []);

  return (
    <div className="wraper-inner bg-grey  space-top">



      <section className="add-member pt-4 pb-5 min-box">
        <div className="container calender_box">
          <Calendar
            defaultDate={new Date()}
            localizer={localizer}
            events={myEvents}
            onSelectEvent={handleSelectEvent}
            // onSelectSlot={handleSelectSlot}
            selectable
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
            eventPropGetter={(event, start, end, isSelected) => {
              if (event?.canceled) {
                return { className: 'canceled-event' };
              }

            }}
          />
        </div>
        <br></br>


      </section>
    </div>
  );
};

export default MyCalendar;