import React, { useEffect, useRef, useState } from 'react';
import Modal from "react-bootstrap/Modal";

const PostMedia = ({ data }) => {
  const [show, setShow] = useState(false);
  const [videoStates, setVideoStates] = useState({});
  const videoRefs = useRef([]);
  const modalRef = useRef(null);

  const handleClose = () => setShow(false);

  useEffect(() => {
    const observers = [];

    videoRefs.current.forEach((videoRef, index) => {
      if (videoRef) {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting && entry.intersectionRatio === 1) {
              // Restore the mute state when the video comes back into view
              const isMuted = videoStates[index]?.isMuted ?? true;
              videoRef.muted = isMuted;

              videoRef.play().catch(error => {
              });
            } else {
              // Save the current mute state before pausing the video
              const isMuted = videoRef.muted;
              setVideoStates(prevStates => ({
                ...prevStates,
                [index]: { isMuted }
              }));

              videoRef.pause();
            }
          });
        }, { threshold: 1.0 });

        observer.observe(videoRef);
        observers.push(observer);
      }
    });

    return () => {
      observers.forEach(observer => observer.disconnect());
    };
  }, [videoStates]);

  const handleVideoClick = (index) => {
    const videoElement = videoRefs.current[index];
    if (videoElement.paused) {
      videoElement.play();
    } else {
      videoElement.pause();
    }
  };

  return data?.fileuploaded?.length
    ? data?.fileuploaded?.map((file, i) => {
        if (file?.mimetype?.includes("image")) {
          return (
            <div key={i} className="d-flex align-items-center justify-content-center postmedia mb-3">
              <img src={file?.path} alt="post" onClick={() => setShow(true)} />
              <Modal ref={modalRef} contentClassName="bg-transparent border-0 align-items-center justify-content-center" centered show={show} onHide={handleClose} animation={false}>
                <Modal.Body>
                  <img className="file-media-img" src={file?.path} alt="post" style={{ maxHeight: 800, maxWidth: 800 }} />
                </Modal.Body>
              </Modal>
            </div>
          );
        } else if (file?.mimetype?.includes("video")) {
          return (
            <div key={i} className="d-flex align-items-center justify-content-center postmedia mb-3">
              <video
                controls
                poster={file?.thumbnail}
                ref={(el) => videoRefs.current[i] = el}
                onClick={() => handleVideoClick(i)}
                muted={videoStates[i]?.isMuted ?? true} // Initialize video state from videoStates
              >
                <source src={file?.path} type={file?.mimetype} />
              </video>
            </div>
          );
        } 
        else if (file?.mimetype?.includes("application")) {
          return (
            <a className="mb-2 d-inline-block" key={i} href={file?.path} target={file?.mimetype?.includes("application/pdf") ? "_blank" : ""} rel="noreferrer">
              <i className="ti ti-paperclip"></i> Please find attached
            </a>
          );
        }
      })
    : null;
};

export default PostMedia;
